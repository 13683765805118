import _common from "./lib/utils/common";
import _deflate from "./lib/deflate";
import _inflate from "./lib/inflate";
import _constants from "./lib/zlib/constants";
var exports = {};
var assign = _common.assign;
var deflate = _deflate;
var inflate = _inflate;
var constants = _constants;
var pako = {};
assign(pako, deflate, inflate, constants);
exports = pako;
export default exports;